import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import logo from "../assests/logo.png";
import { Spinner } from "react-bootstrap";

import b1 from '../assests/images/b4.jpg'
import b2 from '../assests/images/b5.jpg'
import b3 from '../assests/images/b6.jpg'
// Static data array
const blogsData = [
  {
    id: "1",
    title: "The Future of AI in Education",
    banner: b1,
    excerpt: "Explore how AI is revolutionizing the education sector, providing personalized learning experiences and enhancing educational outcomes.",
    description: "The integration of Artificial Intelligence in education is not just a futuristic concept; it is a reality that is transforming the learning landscape. AI provides personalized learning experiences, helps in identifying the strengths and weaknesses of students, and offers tailored solutions to improve educational outcomes. It is paving the way for more interactive and engaging educational tools, making learning more accessible and efficient. " + 
    "This is the full description of the first blog. ".repeat(10),
  },
  {
    id: "2",
    title: "Top 10 Machine Learning Algorithms",
    banner: b2,
    excerpt: "A comprehensive guide to the most popular machine learning algorithms, their applications, and how they are changing various industries.",
    description: "Machine learning algorithms are the backbone of modern AI applications. They enable systems to learn from data and make decisions with minimal human intervention. From healthcare to finance, these algorithms are revolutionizing industries by providing insights, improving efficiency, and enabling the development of innovative solutions. " +
    "This is the full description of the second blog. ".repeat(10),
  },
  {
    id: "3",
    title: "Cybersecurity in the Digital Age",
    banner: b3,
    excerpt: "Learn about the latest trends in cybersecurity and how businesses can protect themselves against evolving digital threats.",
    description: "In an era where digital transformation is at its peak, cybersecurity has become more crucial than ever. With the rise of cyber threats, businesses need to adopt robust security measures to protect their data and ensure privacy. This blog delves into the latest trends in cybersecurity, including the importance of encryption, the role of AI in threat detection, and best practices for safeguarding digital assets. " + 
    "This is the full description of the third blog. ".repeat(10),
  },
];


const Blogsdetail = () => {
  const { id } = useParams();
  const [apiBlogsDetail, setApiBlogsDetail] = useState({});
  const [loading, setLoading] = useState(true);
  const [showReadMore, setShowReadMore] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const blogDetail = blogsData.find(blog => blog.id === id);
    if (blogDetail) {
      setApiBlogsDetail(blogDetail);
      setShowReadMore(blogDetail.description.length > 2500);
    }
    setLoading(false);
  }, [id]);

  if (loading) {
    return <Spinner animation="border" variant="primary" />
    ;
  }

  const truncatedDescription = showReadMore
    ? apiBlogsDetail.description.slice(0, 2000)
    : apiBlogsDetail.description;

  return (
    <div>
      <div className="container ">
        <div className="col-lg-8 mx-auto py-3">
          <Link to={"/blogs"}>
            <button className="All-blogs text-white py-1 my-3" >All Blogs</button>
          </Link>
          <div className="blogdetailtittle">
            <h1>{apiBlogsDetail.title}</h1>
          </div>

          <div>
            {/* <HorizontalAdd dataAdSlot="2473443454" /> */}
          </div>
          <div className="col-lg-8 mx-auto">
            <div className="blogdetailimage">
              <img
                src={apiBlogsDetail.banner}
                width="100%"
                alt={apiBlogsDetail.title}
              />
            </div>
          </div>

          <div className="blogdetailtext py-3">
            <p>{apiBlogsDetail.excerpt}</p>

            <div className="text-center">
              {/* <SquareAdd dataAdSlot="9853240024" /> */}
            </div>
            <p>{truncatedDescription}</p>
            {showReadMore && (
              <div className="text-center">
                <button
                  onClick={() => setShowReadMore(false)}
                  className="mode-btn"
                >
                  Read More
                </button>
              </div>
            )}
          </div>
          <div className="d-flex pb-2" style={{ justifyContent: "end" }}>
            <div
              className="mt-1"
              style={{ width: "40px", height: "40px", overflow: "hidden" }}
            >
              <img
                src={logo}
                alt="Logo"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
            <div className="px-2">
              <p className="m-0">
                <i style={{ color: "#EF6837 " }}>Author</i>
              </p>
              <small>
              <b className="secondary-color" style={{ fontSize: "12px" }}>
                            MY Cognity
                          </b>              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogsdetail;
