import React, { useEffect } from 'react';
import { Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Area, ComposedChart } from 'recharts';

const sampledata = [
    { x: 'Sun', uv: 4000, pv: 2400 },
    { x: 'Mon', uv: 3000, pv: 1398 },
    { x: 'Tue', uv: 2000, pv: 9800 },
    { x: 'Wed', uv: 2780, pv: 3908 },
    { x: 'Thu', uv: 1890, pv: 4800 },
    { x: 'Friday', uv: 2390, pv: 3800 },
    { x: 'Sat', uv: 3490, pv: 4300 },
];

const LineChartGraph = () => {

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // if (loading) {
    //     return (
    //         <div className="loader-block">
    //             <div className="loader"></div>
    //         </div>
    //     );
    // } else if (isValid) {
    //     return (
    //         <div className="loader-block">
    //             <Alert variant="danger" className="text-center">
    //                 <p>Invalid Chart Data</p>
    //             </Alert>
    //         </div>
    //     );
    // }

    return (
        <ResponsiveContainer width="100%" height={420}>
            <ComposedChart
                data={sampledata}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={false} />
                <XAxis dataKey="x" interval="preserveStartEnd" padding={{ left: 20, right: 20, }} />
                <YAxis />
                <Tooltip />
                {/* <Legend /> */}
                <Area type="monotone" dataKey="uv" stroke="#564FFD" fill="#F7F6FF" />
                <Line type="monotone" dataKey="pv" stroke="#FF6636" />
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default LineChartGraph;
