import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assests/logo.png";
import { Container, Spinner } from "react-bootstrap";
import b1 from '../assests/images/b1 (1).jpg'
import b2 from '../assests/images/b1 (2).jpg'
import b3 from '../assests/images/b1 (3).jpg'
// Static data array
const blogsData = [
  {
    id: "1",
    title: "Embracing AI: Transforming the Future of Training",
    banner: b1,
    excerpt: "Discover how AI is revolutionizing the way we approach training and development, creating personalized and adaptive learning experiences.",
  },
  {
    id: "2",
    title: "Data Security in the Digital Age",
    banner: b2,
    excerpt: "Explore the latest trends and best practices in data security to ensure your organization's information remains protected against threats.",
  },
  {
    id: "3",
    title: "The Rise of Machine Learning in Education",
    banner: b3,
    excerpt: "Learn about the impact of machine learning on education, from automating administrative tasks to enhancing student engagement and outcomes.",
  },
];

const Blogs = () => {
  const [apiBlogs, setApiBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setApiBlogs(blogsData);
    setLoading(false);
  }, []);

  return (
    <>
   
    <Container>
      <div className="blogheading pt-1">
        <h1>Blogs</h1>
      </div>
      {loading ? (
        <Spinner animation="border" variant="primary" />
      ) : (
        apiBlogs?.map((blogItem) => (
          <Link
            to={`/blogdetails/${blogItem.id}`}
            key={blogItem.id}
            style={{ textDecoration: "none" }}
          >
            <div
              className="card my-3 border-0 bg-transparent"
              style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
            >
              <div className="row no-gutters align-items-center ">
                <div className="col-md-5 col-12  pr-0" style={{ height: "auto" }}>
                  <div className="blogimg">
                    <img
                      src={blogItem.banner}
                      width={"100%"}
                      height={"100%"}
                      style={{ objectFit: "cover" }}
                      alt={blogItem.title}
                    />
                  </div>
                </div>
                <div className="col-md-7 col-12  pl-0">
                  <div className="card-body">
                    <h5 className="card-title">{blogItem.title}</h5>
                    <p className="card-text">
                      {blogItem.excerpt?.length > 60
                        ? blogItem.excerpt.substring(0, 60) + "..."
                        : blogItem.excerpt}
                    </p>
                    <div className="d-flex pb-2">
                      <div
                        className="mt-1"
                        style={{
                          width: "40px",
                          height: "40px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={logo}
                          alt="Logo"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div className="px-2">
                        <p className="m-0">
                          <i style={{ color: "#EF6837", fontSize: "14px" }}>
                            Author
                          </i>
                        </p>
                        <small>
                          <b className="secondary-color" style={{ fontSize: "12px" }}>
                            MY Cognity
                          </b>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        ))
      )}
    </Container>
    </>
  );
};

export default Blogs;
