import React, { useState, useEffect } from 'react';
import { Container, Dropdown, ButtonGroup, Image, Offcanvas } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import logo from '../../assests/Polygon 2.png';
import logo_header from '../../assests/header-logo.png';
// import notificationIcon from '../../assests/notification.png';
import searchIcon from '../../assests/search.png';
import { TbZoomCancel } from 'react-icons/tb';
import useLogout from '../../hooks/useLogout';
import { GetUserName, GetUserRoles } from '../../utils/auth.utils';
import { menuItem } from './MenuItems';

const SidebarHeader = ({ toggleSidebar, isOpen }) => {
    const { logoutUser } = useLogout();
    const [user, setUser] = useState(() => JSON.parse(localStorage.getItem("user")) || {});
    const fullName = GetUserName();
    const [searchOpen, setSearchOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [showDrawer, setShowDrawer] = useState(false);

    useEffect(() => {
        const handleStorageChange = () => {
            setUser(JSON.parse(localStorage.getItem("user")) || {});
        };

        window.addEventListener('user-update', handleStorageChange);
        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('user-update', handleStorageChange);
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const handleSearchIconClick = () => {
        setSearchOpen(!searchOpen);
    };

    const userRoles = GetUserRoles();
    const isVisible = (visibleOn) => {
        return visibleOn.some(role => userRoles.includes(role));
    };

    const handleCancelSearch = () => {
        setSearchOpen(false);
        setSearchValue('');
    };

    const handleSearchInputChange = (e) => {
        setSearchValue(e.target.value);
    };

    const handleDrawerClose = () => setShowDrawer(false);
    const handleDrawerShow = () => setShowDrawer(true);

    return (
        <header className="header d-flex">
            <div className="d-flex align-items-center top_section d-lg-block d-none header-transition" style={{ minWidth: isOpen ? "330px" : "100px" }}>
                <div className='navbar-brand ' style={{ display: isOpen ? "block" : "none" }} onClick={toggleSidebar}>
                    <Image src={logo_header} width="100%" height="100%" alt="" className='logo-img' style={{ objectFit: 'contain' }} />
                </div>
                <div style={{ display: !isOpen ? "block" : "none" }}>
                    <div style={{ marginLeft: isOpen ? "50px" : "0px" }} className="bars">
                        <Image src={logo} onClick={toggleSidebar} />
                    </div>
                </div>
            </div>
            <Container fluid className='m-auto d-flex d-lg-block align-items-center justify-content-between px-2 px-xl-5 px-lg-5 px-md-5 py-3'>
                <div className="d-lg-none d-block d-flex align-items-center">
                    <FaBars size={23} onClick={handleDrawerShow} />
                </div>
                <div className="header__user">
                    <>
                        {/* {!searchOpen && (
                            <>

                                <button className="notification-bell-icon d-flex justify-content-center align-items-center" onClick={handleSearchIconClick}>
                                    <Image src={searchIcon} alt="search icon" />
                                </button>
                            </>
                        )}
                        {searchOpen && (
                            <div className="search-container">
                                <div className="border rounded-pill p-1 d-flex align-items-center px-3">
                                    <input
                                        type="search"
                                        placeholder="Search Courses"
                                        aria-describedby="button-addon4"
                                        className="form-control bg-none border-0 px-0 search-input"
                                        value={searchValue}
                                        onChange={handleSearchInputChange}
                                    />
                                    <TbZoomCancel onClick={handleCancelSearch} style={{ cursor: 'pointer' }} />
                                </div>
                            </div>
                        )} */}
                        <div className="header__dropdown">
                            <Dropdown as={ButtonGroup}>
                                <Dropdown.Toggle split variant="default" id="dropdown-split-basic" className="custom-dropdown d-flex py-0 px-lg-3 px-0 gap-2 align-items-center">
                                    <div className="avatar">
                                        <Image
                                            src={user.imageUrl ? user.imageUrl : `https://api.dicebear.com/7.x/initials/svg?seed=${fullName}&radius=50`}
                                            width={"100%"}
                                            height={"auto"}
                                            alt=""
                                        />
                                    </div>
                                    <span className="login_details_drop">{`${user.firstname} ${user.lastname}`}</span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <NavLink to={`profile`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Dropdown.Item as="div" className="py-3 custom-item">
                                            My Profile
                                        </Dropdown.Item>
                                    </NavLink>
                                    <Dropdown.Item className="py-3 custom-item" as={Link} to={'/profile'} onClick={logoutUser}>
                                        Sign out
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </>
                </div>
            </Container>

            <Offcanvas show={showDrawer} onHide={handleDrawerClose} placement="start" className="sidebar-small">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Navigation</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='pe-0'>
                    {menuItem.map((item, index) => (
                        isVisible(item.visibleOn) && (
                            <NavLink to={item.path} key={index} className="px-0 link poppins-regular" activeclassname="activeLink">
                                <div className="icon">{item.icon}</div>
                                <div style={{ display: isOpen ? "block" : "none" }} className="link_text">{item.name}</div>
                            </NavLink>
                        )
                    ))}
                </Offcanvas.Body>
            </Offcanvas>
        </header>
    );
};

export default SidebarHeader;
