import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Image, Row, Tab, Tabs } from 'react-bootstrap';
import profile from '../assests/Ellipse 8.png';
import playicon from '../assests/Play.svg';
import started from '../assests/button-started.svg';
import Overview from '../components/Overview/Overview';
import Curriculum from '../components/Curriculum/Curriculum';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetSection, ReviewsCourse } from '../services/createCourse/createCourse.service';
import { activateCourse } from '../services/userDashboard/allCourses.service';
import CourseReviews from "../components/Reviews/Reviews.jsx";
import placeholderVideo from "../assests/vedio/not-found.mp4"

const CourseDetail = ({ roles }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = location.state || {};
    const isAdmin = roles?.includes('admin');
    const [loading, setLoading] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);
    const [key, setKey] = useState('overview');
    const videoRef = useRef(null);
    const [course, setCourse] = useState(null);
    const [reviews, setReviews] = useState(null);

    const togglePlay = () => {
        const video = videoRef.current;
        video.play();
        setIsPlaying(true);
    };

    const handleNavigateContent = async () => {
        if (isAdmin) {
            navigate(`/all-courses/course-detail/content/${course?._id}`, { state: { sections: course, courseId: course?._id } });
        } else {
            try {
                const response = await activateCourse(course?._id);
                console.log("response", response.data);
                navigate(`/all-courses/course-detail/content/${course?._id}`, { state: { sections: course, courseId: course?._id } });
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    };

    const handleVideoEnded = () => {
        setIsPlaying(false);
    };

    const handleEdit = () => {
        navigate('/all-courses/edit-course', { state: { id: course?._id } });
    };

    const handleVideoPause = () => {
        const video = videoRef.current;
        video.pause();
        setIsPlaying(false);
    };

    const fetchAllCourses = async () => {
        try {
            const response = await GetSection(id);
            setCourse(response?.data);
            console.log("Course response data", response?.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error fetching sections:", error);
        }
    };

    const fetchCourseReview = async () => {
        try {
            const response = await ReviewsCourse(id);
            console.log("Reviews response", response?.data?.data);
            setReviews(response?.data?.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error fetching reviews:", error);
        }
    };

    useEffect(() => {
        if (id) {
            fetchAllCourses();
            fetchCourseReview();
        }
    }, [id]);

    return (
        <div className='py-4'>
            {loading ? (
                <div className="loader-block">
                    <div className="loader"></div>
                </div>
            ) : (
                <Row className='mx-0'>
                    <Col md={9}>
                        <h3 className='poppins-bold course-heading'>{course?.title}</h3>
                        <p className='course-sub poppins-regular'>{course?.subTitle}</p>
                        <div className='d-flex align-items-center gap-2'>
                            <div>
                                <Image src={profile} width="100" className="rounded-circle profil-img" />
                            </div>
                            <div>
                                <p className='mb-0 name-font created'>Created by:</p>
                                <p className='mb-0 name-font created-name poppins-medium'>{course?.author}</p>
                            </div>
                        </div>
                        <div className="video-placeholder mt-4 card bg-dark">
                            <video
                                ref={videoRef}
                                controls={isPlaying}
                                src={course?.video || placeholderVideo}
                                style={{ width: "100%", height: "auto", maxHeight: "500px", objectFit: "contain" }}
                                onEnded={handleVideoEnded}
                                onPause={handleVideoPause}
                            />
                            {!isPlaying && (
                                <div className='Player-btn' onClick={togglePlay}>
                                    <Image className='' src={playicon} />
                                </div>
                            )}
                        </div>
                        <div className='py-4 d-flex '>
                            <Button onClick={handleNavigateContent} className='d-flex align-items-center gap-4 p-0 started-btn'>
                                {isAdmin ? (
                                    <>
                                        <div className='py-2 ps-4 poppins-semibold start-btn-text'>View Detail</div>
                                    </>
                                ) : (
                                    <div className='py-2 ps-4 poppins-semibold start-btn-text'>Get Started</div>
                                )}
                                <div className='started-bg py-2 px-3'>
                                    <Image className='' src={started} />
                                </div>
                            </Button>
                            {isAdmin && (
                                <Button className=' ms-3 d-flex align-items-center gap-4 px-4 started-btn' onClick={handleEdit}>
                                    <div className='poppins-semibold text-small'>Edit Course</div>
                                </Button>
                            )}
                        </div>
                        <Row className='mx-0'>
                            <Col md={12}>
                                <div>
                                    <Tabs
                                        activeKey={key}
                                        onSelect={(k) => setKey(k)}
                                        id="fill-tab-example"
                                        className="mb-3 tab-text"
                                        fill
                                    >
                                        <Tab eventKey="overview" title="Overview">
                                            <Overview description={course?.description} courseRequirements={course?.courseRequirements} outline={course?.outline} whoThisCourseIsFor={course?.whoThisCourseIsFor} />
                                        </Tab>
                                        <Tab eventKey="curriculum" title="Curriculum">
                                            <Curriculum sections={course} />
                                        </Tab>
                                        <Tab eventKey="reviews" title="Reviews">
                                            <CourseReviews reviews={reviews} />
                                        </Tab>
                                    </Tabs>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default CourseDetail;
