import React, { useEffect, useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import { GetUsersEmail, SendInvite } from '../../services/TeacherDashboard/Dashboard.service';
import { toast } from 'react-toastify';

const PrivateCourse = ({ formData, setFormData, courseId }) => {
    const { visibility } = formData;
    // const [loading, setLoading] = useState(true);
    const [userEmail, setUserEmail] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedEmails, setSelectedEmails] = useState([]);

    const handleToggleChange = () => {
        setFormData(prevData => ({
            ...prevData,
            visibility: !prevData.visibility
        }));
    };

    const fetchUsersEmail = async () => {
        console.log("Course Id", courseId)
        try {
            const response = await GetUsersEmail(courseId);
            const emailOptions = response?.data?.users.map(user => ({ label: user.email, value: user.email }));
            setUserEmail(emailOptions);
            // setLoading(false);
        } catch (error) {
            console.error("Error fetching Users:", error);
        }
    };

    const handleEmailChange = (selectedOptions) => {
        setSelectedEmails(selectedOptions);
    };

    const handleAddStudents = async () => {
        const emails = selectedEmails.map(option => option.value);
        console.log("Selected Emails: ", emails);
        const toastId = toast.loading("Sending", { closeButton: true });
        try {
            await SendInvite({ courseId, emails });
            toast.update(toastId, {
                render: "Invite sent Successfuly",
                type: "success",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
            setShowModal(false); // Close the modal after sending data
        } catch (error) {
            toast.update(toastId, {
                render: error?.response?.data?.error,
                type: "error",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
            console.error("Error fetching Users:", error);
        }
    };

    useEffect(() => {
        if (courseId) {
            fetchUsersEmail();
        }
    }, [courseId]);

    return (
        <div>
            <div className='d-flex align-items-center gap-2 justify-content-end'>
                <Form.Check
                    type="switch"
                    id="auto-complete-toggle"
                    label="Private Course"
                    checked={visibility}
                    onChange={handleToggleChange}
                />
                {visibility && (
                    <div className='d-flex justify-content-end'>
                        <Button variant="primary" onClick={() => setShowModal(true)} className="d-flex justify-content-center align-items-center login-button primary-bg">
                            Add Students
                        </Button>
                    </div>
                )}
            </div>
            <Modal show={showModal} centered>
                <Modal.Header>
                    <Modal.Title className='feedback-header'>Select Students</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Select
                        isMulti
                        options={userEmail}
                        value={selectedEmails}
                        onChange={handleEmailChange}
                        closeMenuOnSelect={false}
                        placeholder="Select Emails"
                        className="js-example-placeholder-single js-states w-100"
                        classNamePrefix="react-select"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button className='cancel-btn px-4 py-2 d-flex justify-content-center align-items-center' variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button
                        onClick={handleAddStudents}
                        className="d-flex justify-content-center align-items-center login-button primary-bg px-3"
                        disabled={selectedEmails.length === 0}
                    >
                        Add Students
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default PrivateCourse;
