import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { AiOutlineMenu } from 'react-icons/ai';
import { HiOutlineTrash } from 'react-icons/hi';
import { PiPencilLineThin, PiPlus } from 'react-icons/pi';
import CourseCreate from '../Modals/CourseCreate';
import VideoUploadModal from '../Modals/VideoUploadModal';
import TextModal from '../Modals/DescriptionModal';
import NotesUploadModal from '../Modals/NotesUploadModal';
import FileUpload from '../Modals/FileUpload';
import { CreateLecture, CreateSection, UpdateLecture, UpdateVideo, UpdateCaption, UpdateDescription, Updatefile, DeleteSection, DeleteLecture, GetSection, UpdateSectionName, UpdateLectureName, DeleteMedia } from '../../services/createCourse/createCourse.service';
import { toast } from 'react-toastify';
import EditModal from '../Modals/EditModal';
import DeleteModal from '../Modals/DeleteModal ';
import { HiOutlineEye } from 'react-icons/hi2';
import PrivateCourse from './PrivateCourse';

const Curriculum = ({ formData, setFormData, courseId }) => {
    const { manualToggle } = formData;
    console.log("Manual toggle", manualToggle)
    const [sections, setSections] = useState([]);
    const [showSectionNameModal, setShowSectionNameModal] = useState(false);
    const [showLectureModal, setShowLectureModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [currentSectionIndex, setCurrentSectionIndex] = useState(-1);
    const [currentLectureIndex, setCurrentLectureIndex] = useState(-1);
    const [currentEditValue, setCurrentEditValue] = useState('');
    const [deleteTarget, setDeleteTarget] = useState(null);
    const [expandedSections, setExpandedSections] = useState([]);
    const [expandedLectures, setExpandedLectures] = useState([]);
    const [sectionName, setSectionName] = useState('');
    const [lectureName, setLectureName] = useState('');
    const [currentLecture, setCurrentLecture] = useState(null);
    const [sectionLoading, setSectionLoading] = useState(false);
    const [editType, setEditType] = useState(null);

    useEffect(() => {
        if (courseId) {
            fetchSections();
        }
        // eslint-disable-next-line
    }, [courseId]);

    const fetchSections = async () => {
        try {
            setSectionLoading(true);
            const response = await GetSection(courseId);
            console.log("response ", response?.data?.sections)
            setSections(response?.data?.sections);
            setSectionLoading(false);
        } catch (error) {
            console.error("Error fetching sections:", error);
        }
    };

    const handleDeleteMedia = async (lectureId, mediaType, mediaId = null) => {
        try {
            await DeleteMedia({ id: lectureId, mediaType, mediaId });
            toast.success("Media deleted successfully");
            fetchSections(); // Refresh the sections or lectures list
        } catch (error) {
            toast.error("Error deleting media");
        }
    };

    const renderMediaList = (lecture) => (
        <>
            {lecture?.captions && (
                <div className='py-2 px-md-4 bg-curriculum px-2'>
                    <div className='d-flex align-items-md-center justify-content-between mb-lg-1 '>
                        <div className='d-flex align-items-center gap-2'>
                            <p className='course-Subheading-small poppins-semibold mb-0'>Caption</p>
                        </div>
                        <div className='d-flex gap-md-3 gap-2 align-items-center justify-content-end mt-md-0 mt-lg-2'>
                            <HiOutlineTrash className='icon-color-delte' onClick={() => handleDeleteMedia(lecture._id, 'captions')} />
                        </div>
                    </div>
                    <div>
                        <p className='text-muted mb-0 ps-2'> {lecture.captions}</p>
                    </div>
                </div>
            )}
            {lecture?.description && (
                <div className='py-2 px-md-4 bg-curriculum px-2 my-2'>
                    <div className='d-flex align-items-md-center justify-content-between mb-lg-1 '>
                        <div className='d-flex align-items-center gap-2'>
                            <p className='course-Subheading-small poppins-semibold mb-0'>Description</p>
                        </div>
                        <div className='d-flex gap-md-3 gap-2 align-items-center justify-content-end mt-md-0 mt-lg-2'>
                            <HiOutlineTrash className='icon-color-delte' onClick={() => handleDeleteMedia(lecture._id, 'description')} />
                        </div>
                    </div>
                    <div>
                        <p className='text-muted mb-0 ps-2'>{lecture.description}</p>
                    </div>
                </div>
            )}
            {lecture?.notes?.description && (
                <div className='py-2 px-md-4 bg-curriculum  px-2'>
                    <div className='d-flex align-items-md-center justify-content-between mb-lg-1 '>
                        <div className='d-flex align-items-center gap-2'>
                            <p className='course-Subheading-small poppins-semibold mb-0'>Notes</p>
                        </div>
                        <div className='d-flex gap-md-3 gap-2 align-items-center justify-content-end mt-md-0 mt-lg-2'>
                            <HiOutlineTrash className='icon-color-delte' onClick={() => handleDeleteMedia(lecture._id, 'notes')} />
                        </div>
                    </div>
                    <div className='text-muted mb-0 ps-2' dangerouslySetInnerHTML={{ __html: lecture.notes.description }}>

                    </div>
                </div>
            )}
            {lecture?.notes?.file.length > 0 && (
                <div className='py-2 px-md-4 bg-curriculum px-2 my-2'>
                    <div className='d-flex align-items-md-center justify-content-between mb-lg-1 '>
                        <div className='d-flex align-items-center gap-2'>
                            <p className='course-Subheading-small poppins-semibold mb-0'>Notes File</p>
                        </div>
                        <div className='d-flex gap-md-3 gap-2 align-items-center justify-content-end mt-md-0 mt-lg-2'>
                            <a href={lecture.notes.file} target="_blank" rel="noopener noreferrer"> <HiOutlineEye className='icon-color' /></a>
                            <HiOutlineTrash className='icon-color-delte' onClick={() => handleDeleteMedia(lecture._id, 'notes', lecture.notes.file)} />
                        </div>
                    </div>
                </div>
            )}
            {lecture?.file?.length > 0 &&
                lecture.file.map((file, index) => (
                    <div className='py-2 px-md-4 bg-curriculum px-2 my-2'>
                        <div key={index} className='d-flex align-items-md-center justify-content-between mb-lg-1 '>
                            <div className='d-flex align-items-center gap-2'>
                                <p className='course-Subheading-small poppins-semibold mb-0'>Attach File</p>
                            </div>
                            <div className='d-flex gap-md-3 gap-2 align-items-center justify-content-end mt-md-0 mt-lg-2'>
                                <a href={file} target="_blank" rel="noopener noreferrer"> <HiOutlineEye className='icon-color' /></a>
                                <HiOutlineTrash className='icon-color-delte' onClick={() => handleDeleteMedia(lecture._id, 'file', file)} />
                            </div>
                        </div>
                    </div>
                ))}
            {lecture?.video?.length > 0 &&
                lecture.video.map((video, index) => (
                    <div className='py-2 px-md-4 bg-curriculum px-2 my-2'>
                        <div key={index} className='d-flex align-items-md-center justify-content-between mb-lg-1 '>
                            <div className='d-flex align-items-center gap-2'>
                                <p className='course-Subheading-small poppins-semibold mb-0'>Video {index + 1}</p>
                            </div>
                            <div className='d-flex gap-md-3 gap-2 align-items-center justify-content-end mt-md-0 mt-lg-2'>
                                <a href={video} target="_blank" rel="noopener noreferrer"> <HiOutlineEye className='icon-color' /></a>
                                <HiOutlineTrash className='icon-color-delte' onClick={() => handleDeleteMedia(lecture._id, 'video', video._id)} />
                            </div>
                        </div>
                    </div>
                ))}
        </>
    );

    const handleSectionName = async (newName) => {
        if (!courseId) {
            toast.error("Please Save Basic Information First", {
                type: "error",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
        } else {
            const toastId = toast.loading("Adding Section", { closeButton: true });
            try {
                await CreateSection({ sectionNum: sections.length + 1, name: newName, courseId });
                setShowSectionNameModal(false);
                setSectionName('');
                fetchSections();
                toast.update(toastId, {
                    render: "Section added successfully",
                    type: "success",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
            } catch (error) {
                toast.update(toastId, {
                    render: error?.response?.data?.message,
                    type: "error",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
                console.error("Error adding section:", error);
            }
        }
    };

    const handleLectureName = async (newName) => {
        if (!courseId) {
            toast.error("Please Save Basic Information First", {
                type: "error",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
        } else {
            if (currentSectionIndex !== -1) {
                const toastId = toast.loading("Lecture Adding", { closeButton: true });
                try {
                    const sectionId = sections[currentSectionIndex]._id;
                    await CreateLecture({ name: newName, lectureNum: sections[currentSectionIndex].lectures.length + 1, sectionId });
                    setShowLectureModal(false);
                    setLectureName('');
                    fetchSections();
                    toast.update(toastId, {
                        render: "Lecture Added successfully",
                        type: "success",
                        isLoading: false,
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeButton: false,
                    });
                    // toggleSection(sectionId);
                } catch (error) {
                    toast.update(toastId, {
                        render: error?.response?.data?.message,
                        type: "error",
                        isLoading: false,
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeButton: false,
                    });
                    console.error("Error adding lecture:", error);
                }
            }
        }
    };

    const openLectureModal = (sectionIndex) => {
        setCurrentSectionIndex(sectionIndex);
        setLectureName('');
        setShowLectureModal(true);
    };

    const openSectionModal = () => {
        setSectionName('');
        setShowSectionNameModal(true);
    };

    const openContentModal = (lecture, type) => {
        setCurrentLecture({ ...lecture, type });
    };

    const handleContentSave = async (content, duration) => {
        if (currentLecture) {
            const toastId = toast.loading("Course Content Updating", { closeButton: true });
            const lectureId = currentLecture._id;
            try {
                if (currentLecture.type === 'file') {
                    await Updatefile({ [currentLecture.type]: content }, lectureId);
                    toast.update(toastId, {
                        render: "file Updated successfully",
                        type: "success",
                        isLoading: false,
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeButton: false,
                    });
                } else if (currentLecture.type === 'notes') {
                    await UpdateLecture({ notes: { file: content, description: duration } }, lectureId);
                    toast.update(toastId, {
                        render: "Notes Updated successfully",
                        type: "success",
                        isLoading: false,
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeButton: false,
                    });
                } else if (currentLecture.type === 'description') {
                    await UpdateDescription({ [currentLecture.type]: content }, lectureId);
                    toast.update(toastId, {
                        render: "Description Updated successfully",
                        type: "success",
                        isLoading: false,
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeButton: false,
                    });
                } else if (currentLecture.type === 'captions') {
                    await UpdateCaption({ [currentLecture.type]: content }, lectureId);
                    toast.update(toastId, {
                        render: "Captions Updated successfully",
                        type: "success",
                        isLoading: false,
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeButton: false,
                    });
                } else if (currentLecture.type === 'video') {
                    await UpdateVideo({ url: content, duration }, lectureId);
                    toast.update(toastId, {
                        render: "Video Updated successfully",
                        type: "success",
                        isLoading: false,
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeButton: false,
                    });
                }
                setCurrentLecture(null);
                // toggleLecture(lectureId);
                fetchSections();
            } catch (error) {
                toast.update(toastId, {
                    render: error?.response?.data?.message,
                    type: "error",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
                console.error("Error updating content:", error);
            }
        }
    };

    const toggleSection = (id) => {
        setExpandedSections(prev => prev.includes(id) ? prev.filter(i => i !== id) : [...prev, id]);
    };

    const toggleLecture = (lectureId) => {
        setExpandedLectures(prev =>
            prev.includes(lectureId) ? prev.filter(id => id !== lectureId) : [...prev, lectureId]
        );
    };

    const handleEdit = (type, id, value) => {
        setCurrentEditValue(value);
        setEditType(type); // Set the type of edit
        setShowEditModal(true);
        if (type === 'section') {
            setCurrentSectionIndex(sections.findIndex(section => section?._id === id));
        } else {
            setCurrentLectureIndex(sections[currentSectionIndex]?.lectures?.findIndex(lecture => lecture?._id === id));
        }
    };

    const handleDelete = (type, id) => {
        setDeleteTarget({ type, id });
        setShowDeleteModal(true);
    };

    const confirmDelete = async () => {
        try {
            if (deleteTarget.type === 'section') {
                await DeleteSection(deleteTarget.id);
                setSections(sections.filter(section => section._id !== deleteTarget.id));
            } else {
                await DeleteLecture(deleteTarget.id);
                const updatedSections = [...sections];
                updatedSections[currentSectionIndex].lectures = updatedSections[currentSectionIndex]?.lectures.filter(lecture => lecture?._id !== deleteTarget.id);
                setSections(updatedSections);
            }
            setShowDeleteModal(false);
            fetchSections();
        } catch (error) {
            console.error("Error deleting item:", error);
        }
    };

    const saveEdit = async (value) => {
        try {
            if (editType === 'section' && currentSectionIndex !== -1) {
                const sectionId = sections[currentSectionIndex]._id;
                await UpdateSectionName({ name: value }, sectionId);
                fetchSections();
            } else if (editType === 'lecture' && currentLectureIndex !== -1) {
                const lectureId = sections[currentSectionIndex]?.lectures[currentLectureIndex]?._id;
                await UpdateLectureName({ name: value }, lectureId);
                fetchSections();
            }
            setShowEditModal(false);
        } catch (error) {
            console.error("Error updating name:", error);
        }
    };
    const handleToggleChange = () => {
        setFormData(prevData => ({
            ...prevData,
            manualToggle: !prevData.manualToggle
        }));
    };

    return (
        <div>
            <div className='py-2 border-up px-lg-3'>
                <div className='d-flex align-items-md-center justify-content-between mb-lg-1 py-md-4 py-2 px-md-4 border-down flex-md-row flex-column'>
                    <div>
                        <h3 className='course-Subheading-small poppins-semibold mb-0'>Course Curriculum</h3>
                    </div>
                    <div className='d-flex gap-2 flex-md-column'>
                        <Form.Check
                            type="switch"
                            id="auto-complete-toggle"
                            label="Allow Manual Complete"
                            checked={manualToggle}
                            onChange={handleToggleChange}
                        />
                    </div>
                </div>
                <div className='pt-4'>
                    <PrivateCourse formData={formData} setFormData={setFormData} courseId={courseId} />
                </div>
            </div>
            <div className='pt-lg-3'>
                {!sectionLoading ? sections.map((section, sectionIndex) => (
                    <Row key={section._id} className='mx-0 bg-curriculum py-md-4 py-2 px-md-4 px-2'>
                        <Col lg={12} className='px-0'>
                            <div className='d-flex align-items-md-center justify-content-between mb-lg-4 mb-2 flex-md-row flex-column'>
                                <div className='d-flex align-items-center gap-2'>
                                    <AiOutlineMenu className='icon-color toggle-sections-icon' onClick={() => toggleSection(section._id)} />
                                    <p className='course-Subheading-small poppins-semibold mb-0'>
                                        Section {sectionIndex + 1}: <span>{section.name}</span>
                                    </p>
                                </div>
                                <div className='d-flex gap-md-4 gap-2 justify-content-end mt-md-0 mt-2'>
                                    <PiPlus className='icon-color' onClick={() => openLectureModal(sectionIndex)} />
                                    <PiPencilLineThin className='icon-color edit-section-icon' onClick={() => handleEdit('section', section?._id, section?.name)} />
                                    <HiOutlineTrash className='icon-color-delte delete-section-icon' onClick={() => handleDelete('section', section?._id)} />
                                </div>
                            </div>
                            {expandedSections.includes(section._id) && (
                                <div>
                                    {section?.lectures?.map((lecture, lectureIndex) => (
                                        <div key={lecture._id} className='py-md-3 py-2 px-md-4 lecture-bg px-2'>
                                            <div className='d-flex align-items-md-center justify-content-between mb-lg-1 flex-md-row flex-column'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <AiOutlineMenu className='icon-color toggle-lectures-icon' onClick={() => toggleLecture(lecture?._id)} />
                                                    <p className='course-Subheading-small poppins-semibold mb-0'>{lecture.name}</p>
                                                </div>
                                                <div className='d-flex gap-md-3 gap-2 align-items-center justify-content-end mt-md-0 mt-lg-2'>
                                                    <Dropdown as={ButtonGroup}>
                                                        <Dropdown.Toggle split variant="default" id="dropdown-split-basic" className="custom-dropdown download-btn d-flex justify-content-center align-items-center mb-0 download-btn-text d-flex gap-2 align-items-center rounded-0">
                                                            Add Content
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => openContentModal(lecture, "video")}>Video</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => openContentModal(lecture, "file")}>Attach Files</Dropdown.Item>
                                                            {/* <Dropdown.Item onClick={() => openContentModal(lecture, "captions")}>Captions</Dropdown.Item> */}
                                                            <Dropdown.Item onClick={() => openContentModal(lecture, "description")}>Description</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => openContentModal(lecture, "notes")}>Lecture Notes</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <PiPencilLineThin className='icon-color' onClick={() => handleEdit('lecture', lecture?._id, lecture?.name)} />
                                                    <HiOutlineTrash className='icon-color-delte' onClick={() => handleDelete('lecture', lecture?._id)} />
                                                </div>
                                            </div>
                                            {expandedLectures.includes(lecture._id) && (
                                                <div className=''>
                                                    {/* {lecture &&
                                                        <p className='text-muted mb-2'>Uploaded Content:</p>
                                                    } */}
                                                    {renderMediaList(lecture)}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </Col>
                    </Row>
                )
                ) : (
                    <div className="">
                        Loading Sections...
                    </div>
                )}
            </div>
            <div className='py-4'>
                <Button
                    className="download-btn d-flex justify-content-center align-items-center px-lg-4 py-lg-3 py-2 mb-0 w-100"
                    onClick={openSectionModal}
                >
                    Add Sections
                </Button>
            </div>
            <CourseCreate
                label="Section Name"
                initialValue={sectionName}
                onSave={handleSectionName}
                onHide={() => setShowSectionNameModal(false)}
                show={showSectionNameModal}
            />
            <CourseCreate
                label="Lecture Name"
                initialValue={lectureName}
                onSave={handleLectureName}
                onHide={() => setShowLectureModal(false)}
                show={showLectureModal}
            />
            {currentLecture && currentLecture.type === "video" && (
                <VideoUploadModal
                    show={true}
                    onHide={() => setCurrentLecture(null)}
                    onSave={handleContentSave}
                />
            )}
            {currentLecture && currentLecture.type === "file" && (
                <FileUpload
                    show={true}
                    onHide={() => setCurrentLecture(null)}
                    onSave={handleContentSave}
                />
            )}
            {currentLecture && (currentLecture.type === "captions" || currentLecture.type === "description") && (
                <TextModal
                    type={currentLecture.type}
                    show={true}
                    onHide={() => setCurrentLecture(null)}
                    onSave={handleContentSave}
                />
            )}
            {currentLecture && currentLecture.type === "notes" && (
                <NotesUploadModal
                    show={true}
                    onHide={() => setCurrentLecture(null)}
                    onSave={handleContentSave}
                />
            )}
            <EditModal
                show={showEditModal}
                onHide={() => setShowEditModal(false)}
                initialValue={currentEditValue}
                onSave={saveEdit}
            />
            <DeleteModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onDelete={confirmDelete}
                targetType={deleteTarget?.type}
            />
        </div>
    );
};

export default Curriculum;
