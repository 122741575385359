import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { PiMonitorPlayDuotone } from 'react-icons/pi';
import { RiStackLine } from 'react-icons/ri';
import { TbClipboardText } from 'react-icons/tb';
import BasicInformation from '../../components/CreateCourseForm/BasicInformation';
import { useLocation, useNavigate } from 'react-router-dom';
import AdvanceInformation from '../../components/CreateCourseForm/AdvanceInformation';
import Curriculum from '../../components/CreateCourseForm/Curriculum';
import { toast } from 'react-toastify';
import { CreateCourseContent, GetSection, UpdateCourse } from '../../services/createCourse/createCourse.service';

const CreateCourse = () => {
    const location = useLocation();
    const [loadingSave, setLoadingSave] = useState(false);
    const [key, setKey] = useState('information');
    const navigate = useNavigate();
    const { id } = location.state || {};
    const [loading, setLoading] = useState(false);
    const [courseId, setCourseId] = useState(id || '');
    const [formData, setFormData] = useState({
        title: '',
        subTitle: '',
        categoryId: '',
        subcategoryId: '',
        topic: '',
        language: '',
        subtitleLang: '',
        level: '',
        duration: '',
        thumbnail: '',
        video: '',
        outline: [''],
        description: '',
        courseRequirements: [''],
        whoThisCourseIsFor: [''],
        manualToggle: '',
        visibility: '',
    });

    const basicInfoRef = useRef(null);
    const advanceInfoRef = useRef(null);
    const handleNext = async () => {
        if (key === 'information') {
            const isValid = basicInfoRef.current.validate();
            if (isValid) {
                saveDataToApi();
            }
        } else if (key === 'lecture') {
            const isValid = advanceInfoRef.current.validate();
            if (isValid) {
                saveDataToApi();
            }
        }
        else if (!courseId) {
            toast.error("Please Save Basic Information First", {
                type: "error",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
        } else {
            saveDataToApi();
        }
    };

    const saveDataToApi = async () => {
        const toastId = toast.loading("Course Updating", { closeButton: true });
        try {
            console.log('form data', formData);
            const response = courseId ? await UpdateCourse(formData, courseId) : await CreateCourseContent(formData);
            console.log('data', response?.data?.data?._id);
            setCourseId(response?.data?.data?._id)
            toast.update(toastId, {
                render: "Course Updated successfully",
                type: "success",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
            if (key === 'information') {
                setKey('lecture');
            } else if (key === 'lecture') {
                setKey('attach');
            } else if (key === 'attach') {
                navigate('/all-courses/course-detail', { state: { id: courseId } });
            }
            // return true;
        } catch (error) {
            // alert("Signup failed. Please check your details and try again.");
            toast.update(toastId, {
                render: error?.response?.data?.message,
                type: "error",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
            console.log("error signing in", error)
            // return false;
        }
    };
    const fetchCoursesById = async () => {
        setLoading(true);
        try {
            const response = await GetSection(courseId);
            console.log("response ", response?.data);
            const data = response?.data;
            setFormData({ ...formData, categoryId: data.categoryId, courseRequirements: data.courseRequirements, description: data.description, subtitleLang: data.subtitleLang, thumbnail: data.thumbnail, title: data.title, topic: data.topic, video: data.video, whoThisCourseIsFor: data.whoThisCourseIsFor, duration: data.duration, language: data.language, level: data.level, outline: data.outline, subTitle: data.subTitle, subcategoryId: data.subcategoryId, manualToggle: data.manualToggle, visibility: data.visibility });
            setLoading(false)
        } catch (error) {
            console.error("Error fetching sections:", error);
            setLoading(false)
        }
    };
    useEffect(() => {
        if (id) {
            fetchCoursesById();
        } else {
            setFormData({
                title: '',
                subTitle: '',
                categoryId: '',
                subcategoryId: '',
                topic: '',
                language: '',
                // subtitleLang: '',
                level: '',
                duration: '',
                thumbnail: '',
                video: '',
                outline: [''],
                description: '',
                courseRequirements: [''],
                whoThisCourseIsFor: [''],
            });
        }
        // eslint-disable-next-line
    }, [id]);

    return (
        <div className='py-4'>
            {loading ? (
                <div className="loader-block">
                    <div className="loader"></div>
                </div>
            ) : (
                <Row className='m-0 p-0'>
                    <Col md={12} className='m-0 p-0 custom-tabs custom-tabs-width'>
                        <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3 tab-text tab-text-width">
                            <Tab eventKey="information" title={
                                <div className='d-flex gap-2 align-items-center'>
                                    <RiStackLine size={24} /> Basic Information
                                </div>
                            }>
                                <BasicInformation
                                    ref={basicInfoRef}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </Tab>
                            <Tab eventKey="lecture" title={
                                <div className='d-flex gap-2 align-items-center'>
                                    <TbClipboardText size={24} /> Advance Information
                                </div>
                            }>
                                <AdvanceInformation
                                    ref={advanceInfoRef}
                                    formData={formData}
                                    setFormData={setFormData}
                                    setLoadingSave={setLoadingSave}
                                />
                            </Tab>
                            <Tab eventKey="attach" title={
                                <div className='d-flex gap-2 align-items-center'>
                                    <PiMonitorPlayDuotone size={24} /> Curriculum
                                </div>
                            }>
                                <Curriculum
                                    formData={formData}
                                    setFormData={setFormData}
                                    courseId={courseId}
                                />
                            </Tab>
                        </Tabs>
                        <div className='d-flex gap-lg-4 pt-lg-5 pt-1' style={{ justifyContent: key === 'information' ? 'flex-end' : 'space-between' }}>
                            {key !== 'information' && (
                                <Button className='px-4 py-2 d-flex justify-content-center align-items-center bg-white primary-color menu-btn download-btn-text' onClick={() => setKey(key === 'attach' ? 'lecture' : 'information')} disabled={loadingSave}>
                                    Previous
                                </Button>
                            )}
                            <Button className="d-flex justify-content-center align-items-center login-button primary-bg px-4 py-2 download-btn-text" onClick={handleNext} disabled={loadingSave}>
                                {key === 'attach' ? 'Save & Preview' : 'Save & Next'}
                            </Button>
                        </div>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default CreateCourse;
