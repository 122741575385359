import React, { useRef } from 'react'
import creator from '../../assests/creator.png'
import arrow_right from '../../assests/arrow-right.svg'
import arrow_left from '../../assests/arrow-right-white.svg'
import quotes from '../../assests/Quotes.svg'
import { Button, Card, Carousel, Col, Container, Image, Row } from 'react-bootstrap'
const CaresoulSection = () => {
    const cardData = [
        {
            text: "Nulla sed malesuada augue. Morbi interdum vulputate imperdiet. Pellentesque ullamcorper auctor ante, egestas interdum quam facilisis commodo. Phasellus efficitur quis ex in consectetur. Mauris tristique suscipit metus, a molestie dui dapibus vel.",
            img: quotes
        },
        {
            text: "Another example of a success story text. This can be replaced with any testimonial or quote.",
            img: quotes
        },
        // Add more card data as needed
    ];

    const carouselRef = useRef(null);

    const handlePrev = () => {
        if (carouselRef.current) {
            carouselRef.current.prev();
        }
    };

    const handleNext = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };
    return (
        <Container fluid className='py-lg-5 py-4 px-2 px-lg-5'>
            <Row className={`justify-content-between align-items-center py-lg-5 px-lg-5 mx-0 flex-column-reverse flex-xl-row`}>
                <Col xl={5} lg={8} className='pt-lg-0 pt-4'>
                    <div>
                        <Col lg={9} md={10}>
                            <div>
                                <h2 className='poppins-semibold teaching-cognity-heading'>20k+ Instructors created their success story with eduguards</h2>
                            </div>
                            <div className='pb-3'>
                                <p className='teaching-para pt-2 font-24'>
                                    Nunc euismod sapien non felis eleifend porttitor. Maecenas dictum eros justo, id commodo ante laoreet nec. Phasellus aliquet, orci id pellentesque mollis.
                                </p>
                            </div>
                            <Carousel indicators={false} controls={false} interval={null} ref={carouselRef} className='custom-carousel'>
                                {cardData.map((card, index) => (
                                    <Carousel.Item key={index} className='custom-carousel-item'>
                                        <Card className='pt-2 px-2 custom-card-bg'>
                                            <Card.Body>
                                                <Image src={card.img} width={'56px'} height={'56px'} />
                                                <Card.Text className='card-text py-3'>
                                                    {card.text}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                            <div className='d-flex gap-2 mt-4'>
                                <Button className='arrow-left-btn' onClick={handlePrev}>
                                    <Image src={arrow_right} />
                                </Button>
                                <Button className='arrow-right-btn' onClick={handleNext}>
                                    <Image className='arrow-color' src={arrow_left} />
                                </Button>
                            </div>
                        </Col>
                    </div>
                </Col>
                <Col xl={6} lg={4}>
                    <div className='image-responsive-cognite pt-lg-0 pt-4' style={{ width: "100%" }}>
                        <Image src={creator} className='Cognite-img' alt='Image' />
                    </div>
                </Col>
            </Row >
        </Container >
    )
}

export default CaresoulSection
