import { GetApiData } from "../../utils/http-client";

export const GetDashbaord = function () {
    return GetApiData(`/active/adminStats`, 'GET', null, true);
}
export const uploadUserFile = function (data) {
    return GetApiData(`/upload/multiple`, 'POST', data, null, true);
}
export const uploadVideo = function (data) {
    return GetApiData(`/upload/video`, 'POST', data, null, true);
}
export const GetUsersEmail = function (id) {
    return GetApiData(`/user/getall/${id}`, 'GET', null, true);
}
export const SendInvite = function (data) {
    return GetApiData(`/invite/create`, 'POST', data, null, true);
}
export const AcceptInvite = function (data) {
    return GetApiData(`/invite/accept`, 'POST', data, null, true);
}